import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  // TODO: Discuss about relative path to import image
  public logoSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(
    `../../../assets/images/smart-logo.png`
  );

  public constructor(private readonly domSanitizer: DomSanitizer) {}

  public ngOnInit(): void {}
}
