<div class="ml-[5%] mt-24 sm:mt-5">
    <smart-breadcrumb
      [config]="breadcrumbConfig"
    ></smart-breadcrumb>
  </div>
<section id="about-us" class="mt-5 md:mt-0">
    <div class="flex mx-4 md:mx-16 items-center md:items-start flex-col md:flex-row mt-5">
        <img 
            alt="banner smart"
            src="assets/images/about-us-image.png" 
            id="image" 
            class="bg-gray-200 w-auto h-[554px] hidden sm:block"
        >
        <div class="flex flex-col justify-center w-full md:w-2/3 mt-4 md:mt-0 px-2 md:px-12 text-justify mb-16">
            <p class="font-bold text-blue-900 text-3xl mb-4">Sobre a Smart Leilões</p>

            <p class="font-bold text-gray-text text-lg mb-2">Quem somos</p>
            <p class="text-[1rem] text-gray-text font-normal">Após anos investindo em leilões de imóveis e vendo pessoas ensinarem a investir nesse mercado da forma errada, decidimos sair do anonimato e ensinar a maneira eficaz e segura de conseguir altas rentabilidades em curto período de tempo com esse investimento.</p>

            <p class="font-bold text-gray-text text-lg mb-2 mt-8">Assessoria Inteligente</p>
            <p class="text-[1rem] text-gray-text font-normal">
                Como os únicos corretores credenciados pela Caixa em todos os 27 estados, temos uma posição privilegiada para oferecer a melhor assessoria no mercado de leilões de imóveis. Com a Smart Leilões - Assessoria Inteligente, você tem suporte completo desde a arrematação até a regularização do imóvel. Basta indicar o CRECI da Smart para um serviço excepcional.

                Transparência e Confiança: Mantemos os mais altos padrões de transparência em cada leilão. Com informações detalhadas e acompanhamento especializado, você terá toda a confiança necessária para fazer suas escolhas.

                Suporte ao Cliente Completo: Desde a consulta inicial até a finalização do processo, nossa equipe está pronta para oferecer suporte em todas as etapas. Seu sucesso e satisfação são nossas maiores prioridades.

                Com a Smart, você está sempre um passo à frente no mercado de leilões de imóveis.

                Smart Leilões Caixa - inovação, expertise e confiança em leilões de imóveis da Caixa Econômica Federal.
            </p>
        </div>
    </div>
    <div class="mt-0 md:mt-5">
        <app-counters></app-counters>
    </div>
</section>
