<footer
  class="relative z-10 w-full bg-gray-100 pt-12 pb-10 lg:pt-[60px] lg:pb-10">
  <div class="px-8 md:px-10">
    <div class="flex flex-wrap">

      <!-- Endereço -->
      <div class="w-full sm:w-2/3 lg:w-4/12">
        <div class="mb-10 w-full">
          <h4 class="uppercase font-normal text-sm text-blue-700 mb-6">Endereço</h4>
          <p class="text-gray-text mb-7 text-xs w-[240px] text-justify">
            Avenida Getúlio Vargas 887, 10° andar
            Funcionários - Belo Horizonte, MG
          </p>
        </div>
      </div>

      <!-- Empresa -->
      <div class="w-full sm:w-1/2 lg:w-3/12">
          <p class="text-dark mb-6 text-sm font-normal text-start text-blue-700 uppercase">
            Empresa
          </p>
          <ul class="text-gray-text text-xs">
            <li>
              <a
                href="/sobre-nos"
                class="text-body-color hover:text-primary mb-2 inline-block text-inherit leading-loose">
                Sobre a Smart Leilões
              </a>
            </li>
            <li> 
              <a
                href="/contato"
                class="text-body-color hover:text-primary mb-2 inline-block text-inherit leading-loose">
                Contate-nos
              </a>
            </li>
            <li>
              <a
                href="https://teamsmartleiloes.com.br/"
                target="_blank"
                class="text-body-color hover:text-primary mb-2 inline-block text-inherit leading-loose">
                Blog
              </a>
            </li>
          </ul>
      </div>

      <!-- Midia Social -->
      <div class="w-full md:w-5/12">
        <div class="mt-4 md:mt-0 mb-10 w-full">
          <h4 class="text-dark mb-6 text-sm font-normal text-blue-700 uppercase">
            Mídia Social
          </h4>
          <div class="mb-6 flex flex-col md:flex-row">
            <div class="w-1/2">
              <a href="https://www.instagram.com/smartleiloes" target="_blank" class="flex flex-row mb-6 align-center">
                  <img src="../../../assets/icons/instagram.svg" class="scale-150 mr-5 md:mt-2"  />
                  <!-- <mat-icon svgIcon="smart-icons:instagram" class="scale-150 mr-5 mt-2" /> -->
                  <div class="flex flex-col">
                    <p class="flex items-start justify-start text-blue-900 font-bold text-xs">Smart Responde</p>
                    <small class="text-xxs font-normal text-gray-description w-[160px]">Lives para responder suas perguntas ao vivo</small>
                  </div>
              </a>

              <a href="https://open.spotify.com/show/0uMnFvIsBx9HZQKxuaLrT3?si=baefee4d81014adb" target="_blank" class="flex flex-row mb-6 md:mb-0">
                <img src="../../../assets/icons/spotify.svg" class="scale-150 mr-5 md:mt-2" />
                <!-- <mat-icon svgIcon="smart-icons:spotify" class="scale-150 mr-5 mt-2" /> -->
                <div class="flex flex-col">
                  <p class="flex items-start justify-start text-blue-900 font-bold text-xs">SmartCast</p>
                  <small class="text-xxs font-normal text-gray-description w-[160px]">Acesse o Spotify e confira todo o conteúdo</small>
                </div>
              </a>
            </div>

            <div class="w-1/2">
              <a href="https://t.me/smartleiloes" target="_blank" class="flex flex-row mb-6">
                <img src="../../../assets/icons/telegram.svg" class="scale-150 mr-5 md:mt-2" />
                <!-- <mat-icon svgIcon="smart-icons:telegram" class="scale-150 mr-5 mt-2" /> -->
                <div class="flex flex-col">
                  <p class="flex items-start justify-start text-blue-900 font-bold text-xs">Telegram</p>
                  <small class="text-xxs font-normal text-gray-description w-[190px]">
                    Tire dúvidas, conte experiências com os leilões ou aprenda com a comunidade.
                  </small>
                </div>
              </a>
              <a href="https://www.youtube.com/@smartleiloes" target="_blank" class="flex flex-row mb-6 md:mb-0">
                <img src="../../../assets/icons/youtube.svg" class="scale-150 mr-5 md:mt-2" />
                <!-- <mat-icon svgIcon="smart-icons:youtube" class="scale-150 mr-6 mt-2"></mat-icon> -->
                <div class="flex flex-col">
                  <p class="flex items-start justify-start text-blue-900 font-bold text-xs">YouTube</p>
                  <small class="text-xxs font-normal text-gray-description w-[190px]">
                    Vídeo novo toda terça e quinta, às 18h para ajudar você a
                    investir em leilões
                  </small>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p class="text-body-color text-center text-sm text-blue-950">
        &copy; 2023 Smart Leilões • Todos os direitos reservados
      </p>
    </div>
  </div>
</footer>
