import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelectChange } from '@angular/material/select';

import algoliaSearch from 'algoliasearch';
import { BehaviorSubject, Observable, OperatorFunction, Subject, filter, from, map, of, switchMap, tap, throttleTime } from 'rxjs';

import { CoreModule } from '@/app/core/core.module';
import { AgenciaCaixa, GenericMapPointTypeEnum, PointData, SmartWorkspaceModule, creciMap } from '@smart-leiloes/smart-workspace';
import { TableAgenciasComponent } from './components/table-agencias/table-agencias.component';
import { HttpClient } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { Loader } from '@googlemaps/js-api-loader';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { BreadcrumbConfig } from '@smart-leiloes/smart-workspace';

export interface CityObject {
  label: string;
  value: string;
}

export interface PlaceAutocompleteOption {
  name: string;
  geometry: any;
}

@Component({
  selector: 'app-creci',
  templateUrl: './creci.component.html',
  styleUrls: ['./creci.component.scss'],
  standalone: true,
  imports: [
    CommonModule, CoreModule, 
    TableAgenciasComponent, MatInputModule, MatTooltipModule,
    SmartWorkspaceModule, FormsModule, ReactiveFormsModule,
  ],
})
export class CreciComponent implements OnInit {

  private loader: Loader | null = null;

  private mapSubject = new BehaviorSubject<google.maps.Map | null>(null);

  public selectedLocation$ = new Subject<PlaceAutocompleteOption>();

  private placesAutocompleteService: google.maps.places.AutocompleteService | null = null;

  public placeAutoComplete$: Observable<PlaceAutocompleteOption[]> = of([]);
  public cidadeAutocomplete$: Observable<CityObject[]> = of([]);

  public inputEstados: FormControl<string | null> = new FormControl('');
  public inputPlace: FormControl<any | null> = new FormControl({ value: '', disabled: true });
  public inputCidade: FormControl<any | null> = new FormControl('');

  public allowPlaceSearch = false;

  public agenciasCaixaProximas$ = new BehaviorSubject<AgenciaCaixa[]>([]);

  public agenciasCaixaPointData = new BehaviorSubject<PointData[]>([]);

  public creciNumber = signal('');

  public algoliaSearchClient = algoliaSearch('XA5CWMQI0F', '60cd08d78129b73990f33008c0a74de0');

  public breadcrumbConfig: BreadcrumbConfig = {
    routes: [
      {
        label: 'Início',
        onClick: () => {
          this.router.navigate(['/home']);
        }
      },
      {
        label: 'CRECI Smart',
      }
    ]
  }

  public constructor(
    private httpClient: HttpClient,
    @Inject(PLATFORM_ID) private platformId: string,
    private router: Router,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.loader = new Loader({
        apiKey: 'AIzaSyBss-aQ6QFTukpY3cnA3exnSMEInX89AeE',
      });

      this.loader?.importLibrary('places').then( () => {
        this.placesAutocompleteService = new google.maps.places.AutocompleteService();
        this.inputPlace.enable();
      });
    }
  }

  public handleBaixarPlanilha() {
    window.open('https://storage.googleapis.com/excel-extracoes-imoveis-smart-leiloes/crecis/CRECIs%20Smart.xlsx');
  }

  public placeOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.inputPlace.patchValue(event.option._text?.nativeElement.innerText);
    this.triggerSearchFromAddress();
  }

  public triggerSearchFromAddress(): void {
      this.httpClient.get<{ 
        referenceCoordinates: { lat: number, lng: number},
        agenciasCaixa: AgenciaCaixa[],
      }>('/api/agencias-proximas', {
        params: {
          address: this.inputPlace.value,
        }
      }).pipe(
        tap(
          (res) => {
              const centerCoordinates = res.referenceCoordinates;
              const { lat, lng } = centerCoordinates;
              const allPointsLat: number[] = [lat];
              const allPointsLng: number[] = [lng]
              const agenciasCaixaPointData: PointData[] = res.agenciasCaixa.map(
                agenciaCaixa => {
                  allPointsLat.push(agenciaCaixa.geoJson.coordinates[1])
                  allPointsLng.push(agenciaCaixa.geoJson.coordinates[0])
                  return {
                  id: agenciaCaixa.id,
                  cardData: {
                    header: `Agência ${agenciaCaixa.numero}`,
                    propsList: {
                      'Endereço': agenciaCaixa.endereco,
                      'Telefone': agenciaCaixa.telefone,
                      'Horário atendimento': agenciaCaixa.horarioAtendimento,
                    },
                  },
                  coordinates: {
                    lat: agenciaCaixa.geoJson.coordinates[1],
                    lng: agenciaCaixa.geoJson.coordinates[0],
                  },
                  type: GenericMapPointTypeEnum.AGENCIA_CAIXA,
                }
            });


            const highestLat = Math.max(...allPointsLat);
            const lowestLat = Math.min(...allPointsLat);
            const highestLng = Math.max(...allPointsLng);
            const lowestLng = Math.max(...allPointsLng);
              
              const currentAddressPointData: PointData = {
                id: 'current-address',
                coordinates: { lat, lng },
                type: GenericMapPointTypeEnum.REGULAR,
              };
              
              const map = this.mapSubject.getValue();
              const mapBounds = new google.maps.LatLngBounds({
                east: highestLng,
                west: lowestLng,
                north: highestLat,
                south: lowestLat,
              })
              map?.fitBounds(mapBounds);
              this.agenciasCaixaPointData.next([ currentAddressPointData, ...agenciasCaixaPointData]);
              this.agenciasCaixaProximas$.next(res.agenciasCaixa);
          }
        ),
      ).subscribe()
  }

  public onEstadoSelected(event: MatSelectChange) {
    this.creciNumber.set(this.getCreciFromEstado(event.value));
  }

  public ngOnInit(): void {
    // this.getAllAgenciasCaixa();

    this.placeAutoComplete$ = this.inputPlace.valueChanges.pipe(
      filter(value => Boolean(value && value.length > 2)) as OperatorFunction<string | null | undefined, string>,
      throttleTime(200),
      switchMap(value => {
        if (value && value !== '') {
          return from(
            this.findPlaceFromQuery(value),
          ).pipe(
            map(placeAutocompleteOptions => {
              if (!placeAutocompleteOptions) {
                return [];
              }
              return placeAutocompleteOptions.predictions.map(
                (placeAutocompletePrediction: google.maps.places.AutocompletePrediction) => ({
                  name: placeAutocompletePrediction.description,
                  geometry: {},
                })
              );
            })
          );
        } else {
          return of([]);
        }
      })
    );

    this.cidadeAutocomplete$ = this.inputCidade.valueChanges.pipe(
      filter((value) => Boolean(value && value.length > 2)) as OperatorFunction<
        string | null | undefined,
        string
      >,
      throttleTime(200),
      switchMap((value) => {
        if (value && value !== '') {
          return from(
            this.algoliaSearchClient.search<CityObject>([
              {
                indexName: 'brazil_cities',
                params: {
                  query: value,
                  hitsPerPage: 5,
                },
              },
            ])
          ).pipe(
            map((multipleQueriesResponse) => {
              const results = multipleQueriesResponse.results;
              return (results as any[])[0].hits.map((result: any) => ({
                label: result.label,
                value: result.value,
              }));
            })
          );
        } else {
          return of([]);
        }
      })
    );
  }

  public findPlaceFromQuery(query: string): Promise<google.maps.places.AutocompleteResponse> {
    const request = {
      input: query,
      fields: ['name', 'geometry'],
    };

    return this.placesAutocompleteService!.getPlacePredictions(request, (
          results: any,
          status: google.maps.places.PlacesServiceStatus
        ) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            return results;
          }
        }
    )
  }

  public handleMapInstance(map: google.maps.Map): void {
    this.mapSubject.next(map);
  }

  private getCreciFromEstado(stateName: string) {
    for (const state in creciMap) {
      if (state === stateName) {
        return creciMap[stateName];
      }
    }
    return 'Estado não encontrado';
  }
}
