<main>
  <div class="imovel-details">
    <div class="w-full border-b border-dashed border-gray-light"></div>
    <div id="medidas-imovel" class="mt-6 gap-2 md:gap-6">
      <div
        class="border-[1px] border-gray-light px-2 md:px-4 py-2 flex flex-row items-center rounded-xl gap-2 min-w-[150px] md:min-w-[190px]">
        <img src="assets/icons/area.svg" alt="Ícone de área" />

        <div class="flex flex-col">
          <p class="text-gray-text text-xs">Área construída</p>
          <span class="text-blue-900 text-base">
            <ng-container *ngIf="imovelSelected().areaPrivativa">
              {{ imovelSelected().areaPrivativa!.replace('2','') }}
              {{ imovelSelected().areaPrivativa![imovelSelected().areaPrivativa!.length - 1] !== 'm' ? 'm' : '' }}<sup>2</sup>
            </ng-container>
            <ng-container *ngIf="!imovelSelected().areaPrivativa">
              Não informado
            </ng-container>
          </span>
        </div>
      </div>

      <div
        class="border-[1px] border-gray-light px-2 md:px-4 py-2 flex flex-row items-center rounded-xl gap-2 min-w-[150px] md:min-w-[190px]">
        <img src="assets/icons/area.svg" alt="Ícone de área" />

        <div class="flex flex-col">
          <p class="text-gray-text text-xs">Área Total</p>
          <span class="text-blue-900 text-base">
            <ng-container *ngIf="imovelSelected().areaTotal">
              {{ imovelSelected().areaTotal!.replace('2','') }}
              {{ imovelSelected().areaTotal![imovelSelected().areaTotal!.length - 1] !== 'm' ? 'm' : '' }}<sup>2</sup>
            </ng-container>
            <ng-container *ngIf="!imovelSelected().areaTotal">
              Não informado
            </ng-container>
          </span>
        </div>
      </div>
    </div>

    <div id="info-imovel" class="grid grid-cols-2 md:grid-cols-3 mt-8 mb-4 gap-y-4 gap-x-8 w-full md:w-2/3">
      <div class="min-w-[100px]">
        <p class="text-xs text-gray-text">Vendedor</p>
        <span class="text-base text-blue-900">{{ imovelSelected().origemIntegracao ?? 'Não identificado' | titlecase }}</span>
      </div>

      <div class="min-w-[100px]">
        <p class="text-xs text-gray-text">Modalidade de venda</p>
        <span class="text-base text-blue-900">{{ imovelSelected().modoVenda }}</span>
      </div>

      <div class="min-w-[100px]">
        <p class="text-xs text-gray-text">Tipo de leilão</p>
        <span class="text-base text-blue-900">Extrajudicial</span>
      </div>

      <div class="min-w-[100px]">
        <p class="text-xs text-gray-text">Financiamento</p>
        <span class="text-base text-blue-900">{{
          imovelSelected().aceitaFinanciamentoHabitacional
            ? 'Aceita'
            : imovelSelected().aceitaFinanciamentoHabitacional === false
              ? 'Não aceita'
              : 'Não informado'
        }}</span>
      </div>

      <div class="min-w-[100px]">
        <p class="text-xs text-gray-text">FGTS</p>
        <span class="text-base text-blue-900">{{
          imovelSelected().aceitaFGTS
            ? 'Aceita'
            : imovelSelected().aceitaFGTS === false
              ? 'Não aceita'
              : 'Não informado'
        }}</span>
      </div>

      <div class="min-w-[100px]">
        <p class="text-xs text-gray-text">Consórcio</p>
        <span class="text-base text-blue-900">{{
          imovelSelected().aceitaConsorcio
            ? 'Aceita'
            : imovelSelected().aceitaConsorcio === false
              ? 'Não aceita'
              : 'Não informado'
        }}</span>
      </div>
    </div>

    <div id="descricao-imovel" class="my-12">
      <h3 class="text-blue-900 text-lg font-bold mb-6">Descrição do imóvel</h3>

      <p class="text-blue-900 text-base text-justify">
        {{ imovelSelected().descricao }}
      </p>

      <h3 class="mt-14 text-blue-900 font-bold text-lg mb-6">Documentos</h3>
      <!-- Create computed to check if have any document -->
      <div
        id="acesso-documentos"
        class="grid grid-cols-1 md:grid-cols-3 gap-4"
        *ngIf="imovelSelected()?.documentos?.length ?? 0 > 0; else naoPossuiDocumentos">
        <div
          *ngIf="docMatricula()?.fileReference"
          class="flex flex-col items-center text-center border-[1px] border-gray-light p-6 cursor-pointer rounded-lg hover:shadow-lg"
          (click)="downloadFile(docMatricula()?.fileReference, docMatricula()?.fileName)">
          <img src="assets/icons/download-document.svg" alt="Ícone de documento" class="object-contain w-6 h-6" />
          <p class="text-blue-900 text-base my-1">Matrícula do imóvel</p>
          <!-- <span class="text-gray-description text-xs">456kb</span> -->
        </div>

        <div
          *ngIf="docEdital()?.fileReference"
          class="flex flex-col items-center text-center border-[1px] border-gray-light p-6 cursor-pointer rounded-lg hover:shadow-lg"
          (click)="downloadFile(docEdital()?.fileReference, docEdital()?.fileName)">
          <img src="assets/icons/download-document.svg" alt="Ícone de documento" />
          <p class="text-blue-900 text-base my-1">Edital de venda</p>
          <!-- <span class="text-gray-description text-xs">856kb</span> -->
        </div>

        <div
          *ngIf="docCondicaoVenda()?.fileReference"
          class="flex flex-col items-center text-center border-[1px] border-gray-light p-6 cursor-pointer rounded-lg hover:shadow-lg"
          (click)="downloadFile(docCondicaoVenda()?.fileReference, docCondicaoVenda()?.fileName)">
          <img src="assets/icons/download-document.svg" alt="Ícone de documento" />
          <p class="text-blue-900 text-base my-1">Condições de venda</p>
          <!-- <span class="text-gray-description text-xs">200kb</span> -->
        </div>

        <!-- <div
            *ngIf="docMinuta()?.fileReference"
            class="flex flex-col items-center text-center border-[1px] border-gray-light p-6 cursor-pointer rounded-lg hover:shadow-lg"
            (click)="downloadFile(docMinuta()?.fileReference, docMinuta()?.fileName)"
            >
            <img
              src="assets/icons/download-document.svg"
              alt="Ícone de documento" />
            <p class="text-blue-900 text-base my-1">Minuta de compromisso</p>
            <span class="text-gray-description text-xs">56kb</span>
          </div> -->
      </div>
    </div>

    <div id="localizacao-imovel" class="mt-3">
      <h3 class="text-blue-900 font-bold text-lg mb-6">Onde o imóvel está</h3>

      <div *ngIf="!!imovelSelected()?.coordenadas && windowReference; else naoPossuiCoordenadas">
        <smart-street-view [coordenadas]="imovelSelected().coordenadas"></smart-street-view>
      </div>
    </div>
  </div>
</main>

<ng-template #naoPossuiDocumentos>
  <div>
    <h3 class="font-semibold text-blue-900">Nenhum documento encontrado</h3>
  </div>
</ng-template>

<ng-template #naoPossuiCoordenadas>
  <div>
    <h3 class="font-semibold text-blue-900">Nenhuma coordenada para o imóvel encontrada</h3>
  </div>
</ng-template>
