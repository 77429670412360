import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CustomIconService } from './core/services/custom-icons.service';
import { FirebaseApp, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, isSupported } from "firebase/analytics";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'bip-public';

  private firebaseApp: FirebaseApp;
  public isBrowser: boolean = false;

  public constructor(
    private readonly iconService: CustomIconService,
  ) {
    this.iconService.registerIcons();
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyCegPMQE6PkfYh8Sh2Popqyb9hjRtNDMcE",
      authDomain: "site-smart-leiloes.firebaseapp.com",
      projectId: "site-smart-leiloes",
      storageBucket: "site-smart-leiloes.appspot.com",
      messagingSenderId: "869186621323",
      appId: "1:869186621323:web:c1ff70cbeb827bde18698e",
      measurementId: "G-FJD6KPNKSL"
    };

    // Initialize Firebase
    this.firebaseApp = initializeApp(firebaseConfig);
    const db = getFirestore(this.firebaseApp);
  }

  async ngOnInit(): Promise<void> {
    if (await isSupported()) {
      const analytics = getAnalytics(this.firebaseApp);
    }
  }
}
