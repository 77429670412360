<!-- <div class="ml-[3%] mt-24 sm:mt-5">
  <smart-breadcrumb
    [config]="breadcrumbConfig"
  ></smart-breadcrumb>
</div> -->
<section id="creci-page" class="w-full text-center flex flex-col justify-center">
  <div
    class="flex flex-col items-center w-full px-6 md:px-10 md:pb-10 pt-[104px] md:pt-[104px] bg-gradient-to-t from-blue-gradient_top to-blue-gradient_bottom">
    <h2 class="font-bold text-2xl text-orange-light">CRECIs</h2>
    <h1 class="font-bold text-5xl text-orange-light">SMART Leilões Caixa</h1>

    <p class="my-3 text-white">
      Indique a nossa Assessoria Inteligente nas suas aquisições.<br />
      <span class="font-bold">Selecione o estado do imóvel</span> para encontrar <span class="font-bold">o CRECI.</span>
    </p>

    <div class="w-full flex flex-col items-center">
      <mat-form-field [ngClass]="{ 'mb-5 md:mb-10': !inputEstados.value }" class="w-full md:w-80 mt-4">
        <mat-label>Estados</mat-label>
        <mat-select [formControl]="inputEstados" (selectionChange)="onEstadoSelected($event)">
          <mat-option>Selecione um estado</mat-option>
          <mat-option [value]="'AC'">AC</mat-option>
          <mat-option [value]="'AL'">AL</mat-option>
          <mat-option [value]="'AP'">AP</mat-option>
          <mat-option [value]="'AM'">AM</mat-option>
          <mat-option [value]="'BA'">BA</mat-option>
          <mat-option [value]="'CE'">CE</mat-option>
          <mat-option [value]="'DF'">DF</mat-option>
          <mat-option [value]="'ES'">ES</mat-option>
          <mat-option [value]="'GO'">GO</mat-option>
          <mat-option [value]="'MA'">MA</mat-option>
          <mat-option [value]="'MT'">MT</mat-option>
          <mat-option [value]="'MS'">MS</mat-option>
          <mat-option [value]="'MG'">MG</mat-option>
          <mat-option [value]="'PA'">PA</mat-option>
          <mat-option [value]="'PB'">PB</mat-option>
          <mat-option [value]="'PR'">PR</mat-option>
          <mat-option [value]="'PE'">PE</mat-option>
          <mat-option [value]="'PI'">PI</mat-option>
          <mat-option [value]="'RJ'">RJ</mat-option>
          <mat-option [value]="'RN'">RN</mat-option>
          <mat-option [value]="'RS'">RS</mat-option>
          <mat-option [value]="'RO'">RO</mat-option>
          <mat-option [value]="'RR'">RR</mat-option>
          <mat-option [value]="'SC'">SC</mat-option>
          <mat-option [value]="'SP'">SP</mat-option>
          <mat-option [value]="'SE'">SE</mat-option>
          <mat-option [value]="'TO'">TO</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="bg-white p-4 mb-10 md:mb-0 rounded-lg">
        <ng-container *ngIf="inputEstados.value">
          <div class="text-center mb-2">
            <span class="text-gray-text text-left font-bold text-2xl">
              CRECI: <span class="text-orange-brand">{{ creciNumber() }}</span>
            </span>
            <span class="ml-2">
              <smart-copy-item [inputText]="creciNumber()"></smart-copy-item>
            </span>
          </div>
        </ng-container>
        <div class="text-center text-gray-text text-left font-bold text-2xl">
          <span>Razão Social:</span>&nbsp;LP2R Corretagem LTDA
        </div>
      </div>
    </div>
  </div>

  <div
    class="w-full h-fit flex flex-col md:flex-row py-5 md:py-1 justify-between items-center px-6 md:px-10 bg-gray-off">
    <div class="flex flex-col text-start w-full my-2">
      <h1 class="text-base md:text-xl text-blue-900 font-bold">Planilha CRECI completa disponível para download!</h1>
      <p class="text-gray-text text-sm md:text-base font-normal">
        Acesse a planilha completa da única Assessora Caixa credenciada nos 27 estados brasileiros.
      </p>
    </div>

    <div class="flex flex-col md:flex-row items-center w-full justify-center md:justify-end">
      <button
        class="
          text-lg text-white bg-blue-secondary py-3 px-6 rounded hover:bg-opacity-90 
          md:w-1/2 
          sm:w-full
          "
        (click)="handleBaixarPlanilha()">
        <mat-icon>download</mat-icon>
        Baixar CRECIs
      </button>
    </div>
  </div>

  <div class="my-2">
    <div class="text-3xl font-bold blue-80">
      Agência Caixa
    </div>
    <div class="p-4 text-lg">
      <p>
        Ao escolher a agência Caixa, dê preferência a alguma que você já tenha um bom relacionamento. Abaixo você
        pode encontrar as agências mais próximas do seu endereço.
      </p>
      <p>
        Alternativamente, você pode indicar a agência
        <span class="blue-80 font-bold">nº 1491</span><span class="ml-1"><smart-copy-item inputText="1491"></smart-copy-item></span>
        de Inhapim/MG, vinculada ao nosso CCA parceiro.
      </p>
    </div>
    <div 
      class="
        md:h-[600px]
        sm:h-full
        grid auto-rows-min mt-2
        lg:grid-cols-2 lg:grid-rows-2 lg:grid-rows-[60px_auto]
        md:grid-cols-1
      ">
      <div 
        class="
          p-1
          lg:row-span-1 lg:col-span-1
          md:w-full
        "
      >
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Digite para pesquisar um local...</mat-label>
          <input
            placeholder="Digite seu endereço..."
            matInput
            type="text"
            [formControl]="inputPlace"
            [matAutocomplete]="place"
          />
          <mat-icon 
            matSuffix
          >
            search
          </mat-icon>
          <mat-autocomplete
            #place="matAutocomplete"
            (optionSelected)="placeOptionSelected($event)"
          >
            <mat-option
              *ngFor="let place of placeAutoComplete$ | async"
            >
              {{ place.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div 
        class="
          p-1
          lg:row-span-2 lg:col-span-1
          md:w-full
        "
      >
        <smart-generic-map
          [pointsData$]="agenciasCaixaPointData.asObservable()"
          (finishedLoadingEmitter)="handleMapInstance($event)"
          mapHeight="100%"
          style="width: 100%"
        ></smart-generic-map>
      </div>
      <div
        class="
          p-3 overflow-scroll
          lg:col-span-1 lg:row-span-1
        "
      >
        <ng-container *ngFor="let agenciaCaixa of agenciasCaixaProximas$ | async">
          <div 
            style="box-shadow: 0px 0px 20px #c2c2c2; border: 1px solid #e7e8ee;"
            class="
              mt-2 p-4 rounded-md
              text-left
            "
          >
            <div class="font-bold text-xl">
              Agência {{ agenciaCaixa.numero }}
              <span class="clickable">
                <mat-icon
                  #tooltip="matTooltip"
                  matTooltip="Copiar número"
                  [matTooltipPosition]="'below'"
                  matTooltipHideDelay="250"
                  [smart-copy]="agenciaCaixa.numero"
                >
                  content_copy
                </mat-icon>
              </span>
            </div>
            <div>
              <label class="font-bold">Endereço:</label>
              <span class="ml-1">{{ agenciaCaixa.endereco }}</span>
            </div>
            <div>
              <label class="font-bold">Telefone:</label>
              <span class="ml-1">{{ agenciaCaixa.telefone }}</span>
            </div>
            <div>
              <label class="font-bold">Horário de Atendimento:</label>
              <span class="ml-1">{{ agenciaCaixa.horarioAtendimento }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- <div class="py-5">
    <div class="px-6 md:px-[290px] flex flex-col justify-center items-center">
      <h2 class="text-xl md:text-[27px] text-blue-900 font-bold">Localize o código da agência mais próxima de você</h2>
      <p class="text-gray-text text-sm md:text-base text-center mt-2 mb-5">
        Informe apenas o número da agência na Etapa 3. <br />
        Não é necessário preencher o campo Corresponde Caixa Aqui.
      </p>

      <mat-form-field appearance="outline" hideRequiredMarker class="w-full md:w-[440px]">
        <mat-label>Cidades</mat-label>
        <input
          placeholder="Digite uma cidade..."
          matInput
          type="text"
          #cidadeInput
          [formControl]="inputCidade"
          [matAutocomplete]="cidade" />
        <mat-icon matPrefix>search</mat-icon>
        <mat-autocomplete #cidade="matAutocomplete" (optionSelected)="optionCidadeSelected($event)">
          <mat-option *ngFor="let cidadeItem of cidadeAutoComplete$ | async" [value]="cidadeItem.value">
            {{ cidadeItem.label }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </div>

    <ng-container>
      <app-table-agencias></app-table-agencias>
    </ng-container>

  </div> -->
</section>
