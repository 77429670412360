import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export enum Icons {
  Instagram = 'instagram',
  Spotify = 'spotify',
  Telegram = 'telegram',
  Youtube = 'youtube',
  Copy = 'copy'
}

interface IconRecord {
  namespace: string;
  icon: string;
  iconUrl: string;
  svgIcon: string;
}

@Injectable({
  providedIn: 'root'
})
export class CustomIconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  static readonly SMART_ICONS_NAMESPACE: string = 'smart-icons';
  public readonly SVG_DIR: string = isPlatformServer(this.platformId)
    ? 'http://localhost:4200/assets/icons'
    : './assets/icons';

  private iconRecords: IconRecord[] = [
    {
      namespace: CustomIconService.SMART_ICONS_NAMESPACE,
      icon: 'instagram'
    },
    {
      namespace: CustomIconService.SMART_ICONS_NAMESPACE,
      icon: 'spotify'
    },
    {
      namespace: CustomIconService.SMART_ICONS_NAMESPACE,
      icon: 'youtube'
    },
    {
      namespace: CustomIconService.SMART_ICONS_NAMESPACE,
      icon: 'telegram'
    },
    {
      namespace: CustomIconService.SMART_ICONS_NAMESPACE,
      icon: 'copy'
    },
    {
      namespace: CustomIconService.SMART_ICONS_NAMESPACE,
      icon: 'whatsapp'
    },
    {
      namespace: CustomIconService.SMART_ICONS_NAMESPACE,
      icon: 'area'
    },
    {
      namespace: CustomIconService.SMART_ICONS_NAMESPACE,
      icon: 'download-document'
    }
  ].map(item => ({
    ...item,
    iconUrl: `${this.SVG_DIR}/${item.icon}.svg`,
    svgIcon: `${CustomIconService.SMART_ICONS_NAMESPACE}:${item.icon}`
  }));

  public registerIcons(): void {
    this.iconRecords.forEach(icon => {
      this.matIconRegistry.addSvgIconInNamespace(
        icon.namespace,
        icon.icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.iconUrl)
      );
    });
  }
}
