import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { SmartWorkspaceModule } from '@smart-leiloes/smart-workspace';

import { MaterialModule } from './material/material.module';
import { LayoutModule } from '../layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    LayoutModule,
    SmartWorkspaceModule
  ],
  exports: [MaterialModule, FormsModule, ReactiveFormsModule, LayoutModule, SmartWorkspaceModule]
})
export class CoreModule {}
