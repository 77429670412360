import { CoreModule } from '@/app/core/core.module';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  Signal,
  WritableSignal,
  computed,
  signal
} from '@angular/core';

import { DocumentoImovel, Imovel } from '@smart-leiloes/smart-workspace';

export interface DownloadSmartFile {
  fileReference: string;
  fileName: string;
}

@Component({
  selector: 'app-auction-details-main',
  standalone: true,
  imports: [CommonModule, CoreModule],
  templateUrl: './auction-details-main.component.html',
  styleUrls: ['./auction-details-main.component.scss']
})
export class AuctionDetailsMainComponent {
  public windowReference: Window | undefined = undefined;
  @Input() imovelSelected: Signal<Imovel> = signal({} as Imovel);
  @Input() isLoading!: WritableSignal<boolean>;

  @Output() onDownloadFile = new EventEmitter<DownloadSmartFile>();

  public constructor(@Inject(PLATFORM_ID) readonly platformId: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.windowReference = window;
    }
  }

  public docMatricula = computed(
    () => this.imovelSelected()?.documentos?.find((doc: DocumentoImovel) => doc.category === 'Matrícula')
  );

  public docEdital = computed(
    () => this.imovelSelected()?.documentos?.find((doc: DocumentoImovel) => doc.category === 'edital')
  );

  public docCondicaoVenda = computed(
    () => this.imovelSelected()?.documentos?.find((doc: DocumentoImovel) => doc.category === 'condicao')
  );

  public downloadFile(fileReference?: string, fileName?: string) {
    if (fileReference) {
      this.onDownloadFile.emit({ fileReference, fileName: fileName ?? 'Documento' });
    }
  }
}
