import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { SmartWorkspaceModule } from '@smart-leiloes/smart-workspace';

@Component({
  selector: 'app-counters',
  standalone: true,
  imports: [CommonModule, SmartWorkspaceModule],
  templateUrl: './counters.component.html',
  styleUrls: ['./counters.component.scss']
})
export class CountersComponent {
  mentoradosCount = 3000;
  anosMercadoCount = 6;
  anosMercado = 3;
}
