import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, Inject, PLATFORM_ID, Signal, WritableSignal, computed, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { CoreModule } from '@/app/core/core.module';

import { BehaviorSubject, EMPTY, Observable, filter, map, switchMap, take, takeUntil, tap } from 'rxjs';
import { DestroyService, Imovel, ImagemImovel, BreadcrumbConfig } from '@smart-leiloes/smart-workspace';

import {
  AuctionDetailsMainComponent,
  DownloadSmartFile
} from './components/auction-details-main/auction-details-main.component';
import { AuctionDetailsSideComponent } from './components/auction-details-side/auction-details-side.component';

interface PayloadBuscarImovel {
  hdnImovel: string;
  origemIntegracao: string;
}

const testSegundoLeilao: Imovel = {
  docName: '651f8b916235d4ac48f63163',
  hdnImovel: '8444417541040',
  dataInsercao: new Date('2023-10-06T04:22:36.264Z'),
  endereco: 'RUA 02, N. 644, QD 06-A LT 10',
  bairro: 'PARQUE RESIDENCIAL NOVA FRONTEIRA',
  estado: 'TO',
  cidade: 'GURUPI',
  tipoImovel: 'Casa',
  precoAvaliacao: 92000,
  precoVenda: 92000,
  modoVenda: '2º Leilão',
  descricao: 'DESCRIÇAO DO IMOVEL',
  situacao: '',
  aceitaFGTS: null,
  aceitaConsorcio: false,
  aceitaFinanciamentoHabitacional: true,
  aceitaParcelamento: false,
  temAcaoJudicial: undefined,
  coordenadas: {
    lat: -11.7147203,
    lng: -49.089574
  },
  siteLeiloeiro: 'https://www.3torresleiloes.com.br',
  desconto: 0,
  vendedor: 'CAIXA',
  origemIntegracao: 'CAIXA',
  imagens: [
    {
      order: 0,
      fileReference: '8a281b0dcb9c468ba417522f1d7e47f3'
    }
  ],
  dataUltimaAtualizacao: new Date('2023-10-06T04:22:36.264Z'),
  datasConcorrencia: {
    leilao_1: {
      data_inicio: '2023-11-13T10:00:00.000Z',
      data_fim: '2023-11-22T10:00:00.000Z',
      lance_minimo: 20000,
      site_leiloeiro: 'https://www.3torresleiloes.com.br',
      local_leilao: 'Online',
      nome_leiloeiro: 'MARCOS ROBERTO TORRES'
    },
    leilao_2: {
      data_inicio: '2023-11-22T10:00:00.000Z',
      data_fim: '2023-11-29T00:00:00.000Z',
      lance_minimo: 15000,
      site_leiloeiro: 'https://www.3torresleiloes.com.br',
      local_leilao: 'Online',
      nome_leiloeiro: 'MARCOS ROBERTO TORRES'
    }
  },
  emDisputa: false
};

@Component({
  selector: 'app-auction-details',
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    CoreModule,
    AuctionDetailsMainComponent,
    AuctionDetailsSideComponent
  ],
  standalone: true,
  templateUrl: './auction-details.component.html',
  styleUrls: ['./auction-details.component.scss']
})
export class AuctionDetailsComponent {
  public imovelSelectedSubject = new BehaviorSubject<PayloadBuscarImovel>({} as PayloadBuscarImovel);
  public isLoading: WritableSignal<boolean> = signal(true);

  public imovelSelected = toSignal<Imovel, Imovel>(
    this.imovelSelectedSubject.pipe(
      filter(payload => !!payload.hdnImovel && !!payload.origemIntegracao),
      tap(() => this.isLoading.set(true)),
      switchMap(payload => this.getDetalhesImovel(payload)),
      tap(() => this.isLoading.set(false))
    ),
    {
      initialValue: testSegundoLeilao
    }
  );

  public breadcrumbConfig: BreadcrumbConfig = {
    routes: [
      {
        label: 'Início',
        onClick: () => {
          this.router.navigate(['/home']);
        }
      },
      {
        label: 'Detalhes Leilão',
      }
    ]
  }

  public constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly destroyService: DestroyService,
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    @Inject(PLATFORM_ID) readonly platformId: string,
  ) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.imovelSelectedSubject.next({
        hdnImovel: params.get('hdnImovel')!,
        origemIntegracao: params.get('origemIntegracao')!
      });
    });
  }

  public cityState: Signal<string> = computed(
    () => `${this.imovelSelected()?.cidade} - ${this.imovelSelected()?.estado}`
  );

  public fullAddress: Signal<string> = computed(
    () => `${this.imovelSelected()?.endereco} ${this.imovelSelected()?.bairro ?? ''}`
  );

  public getDetalhesImovel(payload: PayloadBuscarImovel): Observable<Imovel> {
    // return of(testSegundoLeilao);
    if (isPlatformBrowser(this.platformId)) {
      return this.httpClient
        .get<Imovel>('/api/verImovel', {
          params: {
            hdnImovel: payload.hdnImovel,
            origemIntegracao: payload.origemIntegracao
          }
        })
        .pipe(
          takeUntil(this.destroyService),
          map(imovel => {
            if (imovel) {
              imovel.imagens = imovel.imagens?.map((image: ImagemImovel, index: number) => {
                image.order = index + 1;
                return image;
              });
            }

            return imovel;
          })
        );
    }
    return EMPTY;
  }

  public handleDownloadFile({ fileReference, fileName }: DownloadSmartFile) {
    (this.httpClient
      .get(
        `/api/imoveis/downloadDocument/${fileReference}`, 
        { 
          responseType: 'blob',
          headers: {
            'Content-Transfer-Encoding': 'binary',
          }
        }
      ) as Observable<Blob>).pipe(
        tap(blob => {
          if (blob) {
            const anchor = document.createElement('a');
            anchor.download = `${fileName}.pdf`;
            anchor.href = (window.webkitURL || window.URL).createObjectURL(blob).replace('.html', '');
            anchor.click();
          }
        })
      )
      .subscribe();
  }

  public handleFavoritarImovel(hdnImovel: string) {
    alert('SALVAR IMOVEL: ' + hdnImovel);
  }
}
