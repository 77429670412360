import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbConfig, SmartWorkspaceModule } from '@smart-leiloes/smart-workspace';
import { CountersComponent } from './components/counters/counters.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [CommonModule, CountersComponent, SmartWorkspaceModule],
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {

  constructor(
    private readonly router: Router,
  ) {}

  public breadcrumbConfig: BreadcrumbConfig = {
    routes: [
      {
        label: 'Início',
        onClick: () => {
          this.router.navigate(['/home']);
        }
      },
      {
        label: 'Sobre nós',
      }
    ]
  }

  public redirectBlog() {
    window.open('https://teamsmartleiloes.com.br/', '_blank');
  }
}
