import { CoreModule } from '@/app/core/core.module';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { take, tap } from 'rxjs';
import { BreadcrumbConfig } from '@smart-leiloes/smart-workspace';
import { Router } from '@angular/router';

interface FormContato {
  nome: FormControl<string | null>;
  email: FormControl<string | null>;
  celular: FormControl<string | null>;
  mensagem: FormControl<string | null>;
}

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [CommonModule, MatInputModule, CoreModule, HttpClientModule, FormsModule, ReactiveFormsModule],
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  public formContato = new FormGroup<FormContato>({
    nome: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    celular: new FormControl(null, Validators.required),
    mensagem: new FormControl(null, Validators.required)
  });

  public constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly httpClient: HttpClient,
    private readonly router: Router,
  ) {}

  public breadcrumbConfig: BreadcrumbConfig = {
    routes: [
      {
        label: 'Início',
        onClick: () => {
          this.router.navigate(['/home']);
        }
      },
      {
        label: 'Sobre nós',
      }
    ]
  }

  public enviarContato() {
    if (this.formContato.invalid) {
      return this.formContato.markAllAsTouched();
    }

    return this.httpClient
      .post('/api/send-feedback-email', this.formContato.value)
      .pipe(
        take(1),
        tap(() => {
          this.formContato.reset();
          this._snackBar.open('Mensagem enviada com sucesso!', 'OK', {
            horizontalPosition: 'right',
            duration: 3000,
            panelClass: ['smart-snackbar']
          });
        })
      )
      .subscribe();
  }
}
