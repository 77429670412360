<div 
  id="counters" 
  class="
    flex flex-row flex-wrap
    justify-center md:justify-between
    w-full 
    text-center md:text-left 
    gap-y-12 md:gap-y-0
    text-blue-900 font-bold mb-20
    px-0 md:px-24
  "
>
  <div 
    class="
      flex flex-col
      justify-center
      items-center
      w-full md:w-fit
    "
  >
    <span class="text-3xl mr-1">+ de</span>
    <p class="text-[100px]" [countUp]="3000" [duration]="3000">{{ mentoradosCount }}</p>
    <p class="text-3xl">mentorados</p>
  </div>

  <div 
    class="
      flex flex-col
      justify-center
      items-center
      w-full md:w-fit
    "
  >
    <span class="text-3xl mr-1">+ de</span>
    <p class="text-[100px]" [countUp]="6" [duration]="3000">{{ anosMercadoCount }}</p>
    <p class="text-3xl">anos de mercado</p>
  </div>

  <div 
    class="
      flex flex-col 
      justify-center 
      items-center
      w-full md:w-fit
    "
  >
    <p class="text-3xl">O maior <br> hub de <br> leilões de <br> imóveis</p>
  </div>
</div>
