<section [ngStyle]="{ 'margin-top': isMobile ? '80px' : '0px' }" id="banner" class="splide">
      <div class="splide__track">
        <ul class="splide__list">
          <li class="splide__slide">
            <div class="
              px-8 md:px-24 
              md:mt-0
              bg-gradient-to-t from-blue-gradient_top to-blue-gradient_bottom
              "
              [ngStyle]="{ 'height': isMobile ? '100%' : '500px' }"
            >
              <div 
                class="
                  h-full 
                  grid grid-cols-1 
                  md:grid-cols-2 md:grid-rows-1
                  lg:grid-cols-[70%_30%]
                  xl:grid-cols-2
                  gap-y-0 
                  gap-x-12
                  text-gray-100
                "
              >
                <div class="flex flex-col md:pt-6 lg:pt-14 justify-start" [ngClass]="{ 'pt-4': isMobile }">
                  <span>
                    <span 
                      class="
                        futura-font 
                        w-fit sm:w-max 
                        flex flex-wrap sm:flex-nowrap 
                        justify-center sm:justify-normal sm:p-2
                        text-center sm:text-left
                      "
                    >
                      <span 
                        class="
                          w-full text-3xl
                          sm:w-max sm:text-4xl
                          flex flex-wrap sm:flex-nowrap
                          justify-center sm:justify-normal
                        "
                      >
                        Assessoria Inteligente
                      </span>
                      <span 
                        class="
                          ml-2 font-bold text-3xl sm:text-4xl
                        "
                      >
                        CAIXA
                      </span>
                    </span>
                    <div class="mt-3 text-2xl">
                      suporte de especialistas desde a <span class="text-orange-light font-bold">arrematação</span> até 
                      a <span class="text-orange-light font-bold">regularização</span> com a Caixa Econômica Federal
                    </div> 
                    <div class="hidden sm:block mt-5 text-xl">
                      Com investimento em tecnologia e um time qualificado, garantimos 
                      <span class="text-orange-light font-bold">agilidade, confiança e transparência </span> em todo o processo
                    </div>
                  </span>
                </div>
                <div 
                  id="query-map"
                  *ngIf="!isMobile"
                  class="
                    xl:pt-10
                    sm:flex sm:justify-end sm:self-end 
                    row-span-3 sm:mt-0
                  "
                >
                  <div 
                    class="w-full items-center z-50 mb-10" 
                    style="max-width: 400px"
                  >
                    <smart-hover-map 
                      [countImoveis]="imoveisCount" 
                      (stateClick)="queryState($event)"
                    ></smart-hover-map>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!isMobile">
                <img
                src="assets/images/home/rectangle1.png"
                alt="banner"
                class="absolute hidden md:block top-10 right-0 bottom-0" />
                <img
                src="assets/images/home/rectangle2.png"
                alt="banner"
                class="absolute hidden md:block top-10 right-0 bottom-4" />
              </ng-container>
            </div>
          </li>
          <li class="splide__slide">
            <div class="
                w-full h-full
              "
              style="display: flex; justify-content: center; background-color: black"
              [ngStyle]="{ 'height': isMobile ? '100%' : '500px' }"
            >
              <a 
                class="h-full w-full"
                style="display: flex; justify-content: center;" target="_blank" 
                href="https://www.instagram.com/smartleiloes/?hl=pt-br"
              >
                <div style="display: flex; align-items: center; justify-content: center;">
                  <picture>
                    <source srcset="https://storage.googleapis.com/smart-leiloes-caixa-banners/BANNER-INSTAGRAM-MONITOR-GRANDE-1366x500.jpg" media="(min-width: 1366px)" />
                    <source srcset="https://storage.googleapis.com/smart-leiloes-caixa-banners/BANNER-INSTAGRAM-MONITOR-MENOR-1080x500.jpg" media="(min-width: 1080px)" />
                    <source srcset="https://storage.googleapis.com/smart-leiloes-caixa-banners/BANNER-INSTAGRAM-TABLET-768x500.jpg" media="(min-width: 768px)" />
                    <img src="https://storage.googleapis.com/smart-leiloes-caixa-banners/BANNER-INSTAGRAM-MOBILE-576x375.jpg" alt="Instagram Smart Leilões" />
                  </picture>
                </div>
              </a>
            </div>
          </li>
          <li class="splide__slide">
            <div class="
                w-full h-full
              "
              style="display: flex; justify-content: center; background-color: #0091fe;"
              [ngStyle]="{ 'height': isMobile ? '100%' : '500px' }"
            >
              <a 
                class="h-full w-full"
                style="display: flex; justify-content: center;" target="_blank" 
                href="https://leiloariasmart.com.br/"
              >
                <div style="display: flex; align-items: center; justify-content: center;">
                  <picture>
                    <source srcset="https://storage.googleapis.com/smart-leiloes-caixa-banners/BANNER-LEILOARIA-SMART-MONITOR-GRANDE-1366x500.jpg" media="(min-width: 1366px)" />
                    <source srcset="https://storage.googleapis.com/smart-leiloes-caixa-banners/BANNER-LEILOARIA-SMART-MONITOR-MENOR-1080x500.jpg" media="(min-width: 1080px)" />
                    <source srcset="https://storage.googleapis.com/smart-leiloes-caixa-banners/BANNER-LEILOARIA-SMART-TABLET-768x500.jpg" media="(min-width: 768px)" />
                    <img 
                      src="https://storage.googleapis.com/smart-leiloes-caixa-banners/BANNER-LEILOARIA-SMART-MOBILE-576x375.jpg"
                      alt="Leiloaria Smart"
                    />
                  </picture>
                </div>
              </a>
            </div>
          </li>
          <li class="splide__slide">
            <div class="
                w-full h-full
              "
              style="display: flex; justify-content: center; background-color: black"
              [ngStyle]="{ 'height': isMobile ? '100%' : '500px' }"
            >
              <a 
                class="h-full w-full"
                style="display: flex; justify-content: center;" target="_blank" 
                href="https://www.youtube.com/@smartleil%C3%B5es"
              >
                <div style="display: flex; align-items: center; justify-content: center;">
                  <picture>
                    <source srcset="https://storage.googleapis.com/smart-leiloes-caixa-banners/BANNER-YOUTUBE-MONITOR-GRANDE-1366x500_1.jpg" media="(min-width: 1366px)" />
                    <source srcset="https://storage.googleapis.com/smart-leiloes-caixa-banners/BANNER-YOUTUBE-MONITOR-MENOR-1080x500_1.jpg" media="(min-width: 1080px)" />
                    <source srcset="https://storage.googleapis.com/smart-leiloes-caixa-banners/BANNER-YOUTUBE-TABLET-768x500_1.jpg" media="(min-width: 768px)" />
                    <img src="https://storage.googleapis.com/smart-leiloes-caixa-banners/BANNER-YOUTUBE-MOBILE-576x375_1.jpg" alt="Youtube Smart Leilões" />
                  </picture>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
</section>

<section id="busca-imoveis">
  <smart-filtro-imoveis
    [filtroForm]="queryImoveisForm"
    [filtroCount]="filtroCount"
    (afterSearch)="sendQueryImoveis()"
  ></smart-filtro-imoveis>
</section>

<section 
  *ngIf="!filterCardsHidden" 
  class="hidden md:block mt-4"
  id="card-filters"
>
  <smart-filter-cards
    cardImageUrl="assets/images/home/imovel-card.png"
    [cardsConfig]="filterCardsConfig"
    (afterClick)="sendQueryFromPredefinedFilter($event)"></smart-filter-cards>
</section>

<section id="resultados-leiloes" aria-label="resultados-leiloes" class="mt-6">
  <div style="position: relative; z-index: 100">
    <div style="position: absolute; left: -55px; top: 120px">
      <!-- <div
        style="
          display: block;
          width: 170px;
          height: 60px;
          background-color: #ff5c00;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          transform: rotate(-90deg);
        "
        class="clickable"
        (click)="openResultsMap()"
        *ngIf="!showResultsMap && hasSearchedImoveis">
        <div
          style="display: flex; color: white; justify-content: center; align-items: center; width: 100%; height: 100%"
          class="text_open-sans">
          <span><mat-icon>map</mat-icon></span>
          <span class="ml-2" style="font-weight: 700; font-size: 1rem"> Exibir mapa </span>
        </div>
      </div> -->
    </div>
  </div>
  <ng-container *ngIf="!hasSearchedImoveis">
    <div class="mx-10 mb-2 flex justify-between">
      <div>
        <span class="text_open-sans blue-80" style="font-weight: 700; font-size: 2rem"> Imóveis em destaque </span>
      </div>
    </div>
    <div class="card-list-container" class="flex w-full justify-center mb-4">
      <smart-imoveis-card-list
        [imoveis]="imoveisDestaque"
        [pageSize]="currentPageSize"
        [isLoading]="isLoadingQueryImoveisDestaque"
        (clickImovel)="handleClickImovel($event)"
        [hasPagination]="false"
        ></smart-imoveis-card-list>
    </div>
    <section *ngIf="!isMobile" class="mid-page-internal-banner mt-8">
      <smart-mid-page-banner
        imgSrc="../../assets/images/real-estate.jpg"
        (afterActionButtonClick)="downloadPlanilhaCaixa()"
      ></smart-mid-page-banner>
    </section>
  </ng-container>
  <ng-container *ngIf="hasSearchedImoveis">
    <div class="mx-10 mb-6 flex justify-between gap-x-2 md:gap-x-0">
      <div class="text-lg md:text-2xl font-bold">
        <span class="text_open-sans blue-80" style="font-weight: 700; font-size: 2rem">
          {{ isLoadingQuerySearchImoveis ? '' : currentImoveisCount }} Leilões encontrados
        </span>
      </div>
      <div class="flex items-center ordenation">
        <button
            mat-stroked-button
            class="h-max p-2"
            [matMenuTriggerFor]="menu"
          >
          <span class="flex items-center">
            <span class="md:text-base text-sm">
              {{ selectedOrdenacao ? 'Ordem: ' + selectedOrdenacao : 'Ordernar por' }}
            </span>
            <span class="pl-2 md:pl-1 flex items-center">
              <mat-icon>arrow_drop_down</mat-icon>
            </span>
          </span>
        </button>
        <mat-menu #menu="matMenu">
          <!-- <button mat-menu-item (click)="orderResults('MAIS_RECENTES')">Mais recente</button> -->
          <button mat-menu-item (click)="orderResults('MAIS_PROXIMO')">Leilão mais próximo</button>
          <button mat-menu-item (click)="orderResults('MAIS_DESCONTO')">Maior desconto</button>
          <button mat-menu-item (click)="orderResults('MENOS_PRECO')">Menor preço</button>
          <button mat-menu-item (click)="orderResults('MAIS_PRECO')">Maior preço</button>
        </mat-menu>
      </div>
    </div>
    <div class="card-list-filtrada mb-12 flex w-full justify-between flex-wrap md:flex-nowrap">
      <ng-container *ngIf="showResultsMap">
        <div class="wrapper-map">
          <smart-query-map
            [imoveisData$]="imoveisMap$.asObservable()"
            mapHeight="1100px"
            style="width: 100%"
            (afterClusterClick)="afterClusterClick($event)"></smart-query-map>
        </div>
      </ng-container>
      <div class="mx-8 w-full" [ngClass]="showResultsMap ? 'md:w-1/2' : 'w-full'">
        <div *ngIf="showResultsMap" class="mb-10 text-2xl text-blue-900">
          <div class="mb-2">
            <span class="font-bold">{{currentImoveisCount}}</span>
            <span class="font-normal"> Oportunidades encontradas</span>
          </div>
          <hr />
        </div>
        <div class="card-list-container" style="display: flex; width: 100%; justify-content: center">
          <smart-imoveis-card-list
            [imoveis]="searchImoveis"
            [pageSize]="currentPageSize"
            [currentPage]="currentPage"
            [itemsSize]="currentImoveisCount"
            [isLoading]="isLoadingQuerySearchImoveis"
            (clickImovel)="handleClickImovel($event)"
            [hasPagination]="true"
            (afterPageChange)="handlePageChange($event)"
          ></smart-imoveis-card-list>
        </div>
      </div>
    </div>
    <section *ngIf="!isMobile" class="mid-page-internal-banner mt-8">
      <smart-mid-page-banner
        imgSrc="../../assets/images/real-estate.jpg"
        (afterActionButtonClick)="downloadPlanilhaCaixa()"
      ></smart-mid-page-banner>
    </section>
  </ng-container>
</section>

<div 
  class="wrapper-whatsapp-btn-right block hidden md:block"
>
  <button
    class="block whatsapp-green"
    mat-fab
    (click)="redirectWhatsapp()"
    matTooltip="Dúvidas? Mande uma mensagem"
    matTooltipPosition="left"
  >
    <img
      src="../../../assets/icons/whatsapp-white.svg"
      alt="Ícone do Whatsapp"
    />
  </button>
</div>

<div 
  class="wrapper-whatsapp-btn-left block md:hidden"
>
  <button
    class="block whatsapp-green scale-90"
    mat-fab
    (click)="redirectWhatsapp()"
    >
    <img
      src="../../../assets/icons/whatsapp-white.svg"
      alt="Ícone do Whatsapp"
    />
  </button>
</div>
