import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PageEvent } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { BehaviorSubject, finalize, Subject, tap } from 'rxjs';
import { SortDirection } from 'mongodb';
import Splide from '@splidejs/splide';
import {
  CountImoveis,
  Imovel,
  QueryRequestImovel,
  FiltroImoveis,
  FilterCardConfig,
  SmartWorkspaceModule,
  ImoveisQueryResponse,
  ClusterClickEvent,
  FiltroCount,
  CardQueryParams
} from '@smart-leiloes/smart-workspace';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    SmartWorkspaceModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  public defaultPageSize = 24;

  public isMobile = false;

  public imoveisCount: CountImoveis | null = null;

  public searchImoveis: Imovel[] = [];

  public presentationImoveis: Imovel[] = [];

  public currentPageSize = this.defaultPageSize;

  public currentPage: number = 1;

  public imoveisDestaque: Imovel[] = [];

  public selectedOrdenacao: string | null = null;

  public isLoadingQueryImoveisDestaque: boolean = true;

  public isLoadingQuerySearchImoveis = false;

  public hasSearchedImoveis = false;

  public filterCardsHidden = false;

  public showResultsMap = false;

  public currentImoveisCount: number = 0;

  public paginatorCurrentPageIndex: number = 1;

  public resetPaginator$ = new Subject<void>();

  public imoveisMap$ = new BehaviorSubject<Imovel[]>([]);

  public filtroCount: FiltroCount | undefined = undefined;

  private splide?: Splide;

  public queryImoveisForm: FormGroup = new FormGroup<FiltroImoveis>({
    queryDataMode: new FormControl('leilao'),
    dateFrom: new FormControl(null),
    dateTo: new FormControl(null),
    estados: new FormControl<string[]>([]),
    cidades: new FormArray([] as never),
    tiposImoveis: new FormControl<string[]>([]),
    modosVenda: new FormControl<string[]>([]),
    vendedores: new FormControl<string[]>([]),
    priceVendaFrom: new FormControl(null),
    priceVendaTo: new FormControl(null),
    priceAvaliacaoFrom: new FormControl(null),
    priceAvaliacaoTo: new FormControl(null),
    descontoDe: new FormControl(null),
    descontoAte: new FormControl(null),
    aceitaFinanciamentoHabitacional: new FormControl(false),
    aceitaFGTS: new FormControl(false),
    decrescimoPrecoVenda: new FormControl(null),
    emDisputa: new FormControl(null),
    situacao: new FormControl(null),
    orderBy: new FormControl(null)
  });

  public filterCardsConfig: FilterCardConfig[] = [
    {
      firstRowLabel: '50% abaixo',
      secondRowLabel: 'da avaliação',
      queryParams: {
        descontoDe: 50
      },
      background: { color: 'lightgray' }
    },
    {
      firstRowLabel: 'Imóveis até',
      secondRowLabel: '500 mil',
      queryParams: {
        priceVendaTo: 500000
      },
      background: { color: 'lightgray' }
    },
    {
      firstRowLabel: 'Imóveis',
      secondRowLabel: 'em disputa',
      queryParams: {
        emDisputa: true
      },
      background: { color: 'lightgray' }
    },
    {
      firstRowLabel: 'Imóveis com',
      secondRowLabel: 'financiamento',
      queryParams: {
        aceitaFinanciamentoHabitacional: true
      },
      background: { color: 'lightgray' }
    }
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.calculateCurrentPageSize(event.target.innerWidth);
    window.innerWidth < 768 ? (this.isMobile = true) : (this.isMobile = false);
  }

  public constructor(
    private readonly httpClient: HttpClient,
    @Inject(PLATFORM_ID) readonly platformId: string,
    private router: Router
  ) {
    if (isPlatformBrowser(platformId) && window) {
      this.calculateCurrentPageSize(window.innerWidth);
      window.innerWidth < 768 ? (this.isMobile = true) : (this.isMobile = false);
    }
    this.imoveisDestaque = new Array(this.currentPageSize).fill(null);
  }

  private calculateCurrentPageSize(pageWidth: number) {
    if (pageWidth >= 1300) {
      this.currentPageSize = 24;
    } else if (pageWidth >= 980) {
      this.currentPageSize = 24;
    } else {
      this.currentPageSize = 24;
    }
  }

  queryState(state: string) {
    this.queryImoveisForm.get('estados')!.patchValue([state]);
    this.sendQueryImoveis();
    this.scrollToResults();
  }

  private scrollToResults(): void {
    const resultsSectionTop = document.getElementById('resultados-leiloes')!.offsetTop;
    window.scrollTo({ top: resultsSectionTop, behavior: 'smooth' });
  }

  public orderResults(orderResultsType: string): void {
    let orderBy: { property: string; order: SortDirection } | undefined = undefined;
    switch (orderResultsType) {
      case 'MAIS_RECENTES':
        orderBy = {
          property: 'data_insercao',
          order: 'desc'
        };
        this.selectedOrdenacao = 'Mais Recentes';
        break;
      case 'MAIS_PROXIMO':
        orderBy = {
          property: 'data_leilao',
          order: 'asc'
        };
        this.selectedOrdenacao = 'Mais Próximo';
        break;
      case 'MAIS_DESCONTO':
        orderBy = {
          property: 'desconto',
          order: 'desc'
        };
        this.selectedOrdenacao = 'Maior desconto';
        break;
      case 'MAIS_PRECO':
        orderBy = {
          property: 'preco_venda',
          order: 'desc'
        };
        this.selectedOrdenacao = 'Maior Preço';
        break;
      case 'MENOS_PRECO':
        orderBy = {
          property: 'preco_venda',
          order: 'asc'
        };
        this.selectedOrdenacao = 'Menor Preço';
        break;
    }
    this.queryImoveisForm.get('orderBy')!.patchValue(orderBy);
    this.currentPage = 1;
    this.sendQueryImoveis({ orderBy });
  }

  private hasEmptyImoveisInRange(lowerEndCount: number, higherEndCount: number): boolean {
    return this.searchImoveis.slice(lowerEndCount, higherEndCount).some(val => !Boolean(val.hdnImovel));
  }

  public handlePageChange(newPage: number): void {
    this.isLoadingQuerySearchImoveis = true;
    const higherEndCount = this.currentPageSize * newPage;
    const lowerEndCount = higherEndCount - this.currentPageSize;

    if (this.searchImoveis.length < higherEndCount || this.hasEmptyImoveisInRange(lowerEndCount, higherEndCount)) {
      this.sendQueryImoveis({
        offset: this.currentPageSize * (newPage - 1),
        useLastQuery: true,
        isPagination: true
      });
    } else {
      this.isLoadingQuerySearchImoveis = false;
    }
  }

  public sendQueryFromPredefinedFilter(predefinedFilterParams: CardQueryParams): void {
    this.queryImoveisForm.reset();
    Object.entries(predefinedFilterParams).forEach(
      ([ filterProperty, filterValue ]) => {
        this.queryImoveisForm.get(filterProperty)?.patchValue(filterValue);
      }
    );
    this.sendQueryImoveis({
      hideFilterCardsAfterSearch: false
    });
  }

  sendQueryImoveis(
    extraParams: {
      max?: number;
      offset?: number;
      useLastQuery?: boolean;
      orderBy?: { property: string; order: SortDirection };
      skipChangePage?: boolean;
      initialQuery?: boolean;
      isPagination?: boolean;
      hideFilterCardsAfterSearch?: boolean;
      suppressScroll?: boolean;
    } = {}
  ): void {
    if (!extraParams.suppressScroll) {
      const cardsContainerElement = document.getElementsByClassName('card-list-container')?.[0];
  
      if (cardsContainerElement) {
        window.scrollTo({
          behavior: 'smooth',
          top: (cardsContainerElement as HTMLElement).offsetTop - 80,
        })
      }
    }
    
    this.isLoadingQuerySearchImoveis = true;
    this.hasSearchedImoveis = true;
    this.filterCardsHidden = extraParams.hideFilterCardsAfterSearch ?? true;

    if (!extraParams.isPagination) {
      this.searchImoveis = new Array(this.currentPageSize).fill({ hdnImovel: null });
    }

    let request: QueryRequestImovel | null = null;

    const { max, offset, useLastQuery, orderBy, skipChangePage, initialQuery } = extraParams;

    if (useLastQuery) {
      request = this.buildQueryFromForm(); // TODO - get query stored on session
      if (request) {
        if (offset) request.offset = offset;
        request.max = max ?? this.currentPageSize;
        // request.countImoveis = true;
        // if (request.cidades && request.cidades.length > 0 && typeof request.cidades[0] !== 'string') {
        //   request.cidades = (request.cidades as unknown as { nome: string, value: string }[])?.map(cidade => cidade.value)
        // }
        // const filterRequest = {
        //   ...request,
        //   cidades: request.cidades.map(cidadeValue => ({ nome: cidadeValue, value: cidadeValue }))
        // }
        // this.queryImoveisForm.patchValue(filterRequest);
      }
    } else {
      request = this.buildQueryFromForm(extraParams);
    }

    this.httpClient
      .post<{ count: number; records: Imovel[] }>(`/api/imoveis/busca`, request)
      .pipe(
        tap(imoveisResponse => {
          if (imoveisResponse.count !== undefined && this.currentImoveisCount !== imoveisResponse.count) {
            this.searchImoveis = new Array(imoveisResponse.count).fill({ hdnImovel: null });
            this.currentImoveisCount = imoveisResponse.count;
          }

          const lowerEndToUpdate = request!.offset ?? 0;
          const higherEndToUpdate = lowerEndToUpdate + imoveisResponse.records.length;
          let responseImoveisIndex = 0;
          if (this.showResultsMap) this.imoveisMap$.next(this.searchImoveis);

          if (imoveisResponse.count === 0) {
            this.searchImoveis = [];
          }

          for (
            let searchImoveisIndex = lowerEndToUpdate;
            searchImoveisIndex < higherEndToUpdate;
            searchImoveisIndex++
          ) {
            this.searchImoveis[searchImoveisIndex] = imoveisResponse.records[responseImoveisIndex];
            responseImoveisIndex++;
          }

          
        }),
        finalize(() => (this.isLoadingQuerySearchImoveis = false))
      )
      .subscribe();
  }

  public downloadPlanilhaCaixa(): void {
    window.open(
      'https://storage.googleapis.com/excel-extracoes-imoveis-smart-leiloes/excel/Smart%20Leil%C3%B5es%20-%20Im%C3%B3veis%20Caixa.xlsx'
    );
  }

  openResultsMap(): void {
    if (!window) return;
    this.calculateCurrentPageSize(window.innerWidth * 0.6);
    this.imoveisMap$.next(this.searchImoveis);
    this.showResultsMap = true;
  }

  public afterClusterClick(afterClusterClickEvent: ClusterClickEvent) {
    const selectedIds: string[] = afterClusterClickEvent.cluster!.markers!.map((marker: any) => marker.id);

    this.presentationImoveis = this.searchImoveis.filter(imovel => selectedIds.includes(imovel.docName!));
    this.currentImoveisCount = afterClusterClickEvent.cluster!.count;
    this.resetPaginator$.next();
  }

  public handleClickImovel(imovel?: Imovel) {
    if (!imovel?.hdnImovel || !imovel?.origemIntegracao) {
      return;
    }

    window.open(`${window.location.origin}/leilao/${imovel.hdnImovel}/${imovel.origemIntegracao}`, '_blank');
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.httpClient
        .get<CountImoveis>('/api/imoveis-count')
        .pipe(
          tap(imoveisCount => {
            this.imoveisCount = imoveisCount;
            this.filtroCount = {
              estados: imoveisCount.estados
            };
          })
        )
        .subscribe();
      this.httpClient
        .get<ImoveisQueryResponse>(`/api/busca-destaques?max=${this.currentPageSize}`)
        .pipe(
          tap(imoveisResponse => {
            this.imoveisDestaque = imoveisResponse.records;
            this.isLoadingQueryImoveisDestaque = false;
          })
        )
        .subscribe();
        
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId) && window) {
      this.splide = new Splide( '.splide', {
        type: 'loop',
        perPage: 1,
        autoplay: true,
        interval: 3000,
        pauseOnHover: true,
      });
      this.splide.mount();
    }
  }

  private buildQueryFromForm(
    extraParams: {
      max?: number;
      offset?: number;
      useLastQuery?: boolean;
      skipChangePage?: boolean;
      initialQuery?: boolean;
    } = {}
  ): QueryRequestImovel {
      const priceVendaFrom = this.queryImoveisForm.get('priceVendaFrom')?.value ? parseInt(this.queryImoveisForm.get('priceVendaFrom')?.value.replaceAll('.', ''), 10) : undefined;
      const priceVendaTo = this.queryImoveisForm.get('priceVendaTo')?.value ? parseInt(this.queryImoveisForm.get('priceVendaTo')?.value.replaceAll('.', ''), 10) : undefined;
      const priceAvaliacaoFrom = this.queryImoveisForm.get('priceAvaliacaoFrom')?.value ? parseInt(this.queryImoveisForm.get('priceAvaliacaoFrom')?.value.replaceAll('.', ''), 10) : undefined;
      const priceAvaliacaoTo = this.queryImoveisForm.get('priceAvaliacaoTo')?.value ? parseInt(this.queryImoveisForm.get('priceAvaliacaoTo')?.value.replaceAll('.', ''), 10) : undefined;
      const dateFromValue = this.queryImoveisForm.get('dateFrom')?.value;
      const dateToValue = this.queryImoveisForm.get('dateTo')?.value;
      let dateFrom, dateTo = undefined;
      if (dateFromValue) {
        const dateFromDateObject = new Date(dateFromValue);
        dateFromDateObject.setUTCHours(0);
        dateFromDateObject.setUTCMinutes(0);
        dateFromDateObject.setUTCSeconds(0);
        dateFromDateObject.setUTCMilliseconds(0);
        dateFrom = dateFromDateObject.toISOString();
      }

      if (dateToValue) {
        const dateToDateObject = new Date(dateToValue);
        dateToDateObject.setUTCHours(23);
        dateToDateObject.setUTCMinutes(59);
        dateToDateObject.setUTCSeconds(59);
        dateToDateObject.setUTCMilliseconds(999);
        dateTo = dateToDateObject.toISOString();
      }
    return {
      dateFrom,
      dateTo,
      queryDataMode: this.queryImoveisForm.get('queryDataMode')?.value ?? undefined,
      modoVenda:
        this.queryImoveisForm.get('modosVenda')!.value?.length > 0
          ? this.queryImoveisForm.get('modosVenda')!.value
          : undefined,
      cidades:
        this.queryImoveisForm.get('cidades')!.value?.length > 0
          ? this.queryImoveisForm.get('cidades')!.value.map((cidade: { nome: string; value: string }) => cidade.value)
          : undefined,
      estados:
        this.queryImoveisForm.get('estados')!.value?.length > 0
          ? this.queryImoveisForm.get('estados')!.value
          : undefined,
      tipoImovel:
        this.queryImoveisForm.get('tiposImoveis')!.value?.length > 0
          ? this.queryImoveisForm.get('tiposImoveis')!.value
          : undefined,
      desagioFrom:
        this.queryImoveisForm.get('descontoDe')!.value !== 0
          ? this.queryImoveisForm.get('descontoDe')!.value ?? undefined
          : undefined,
      desagioTo:
        this.queryImoveisForm.get('descontoAte')!.value !== 99
          ? this.queryImoveisForm.get('descontoAte')!.value ?? undefined
          : undefined,
      vendedores:
        this.queryImoveisForm.get('vendedores')!.value?.length > 0
          ? this.queryImoveisForm.get('vendedores')!.value
          : undefined,
      priceVendaFrom,
      priceVendaTo,
      priceAvaliacaoFrom,
      priceAvaliacaoTo,
      aceitaFinanciamentoHabitacional:
        this.queryImoveisForm.get('aceitaFinanciamentoHabitacional')?.value === true ? true : undefined,
      aceitaFGTS: this.queryImoveisForm.get('aceitaFGTS')?.value === true ? true : undefined,
      emDisputa: this.queryImoveisForm.get('emDisputa')?.value === true ? true : undefined,
      decrescimoPrecoVenda: this.queryImoveisForm.get('decrescimoPrecoVenda')?.value === true ? true : undefined,
      max: extraParams.max ?? this.currentPageSize,
      offset: extraParams.offset ?? 0,
      orderBy: this.queryImoveisForm.get('orderBy')?.value ?? undefined,
      countImoveis: true
    };
  }

  public redirectWhatsapp() {
    window.open(
      'https://api.whatsapp.com/send?phone=5511991165865&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es',
      '_blank'
    );
  }
}
