import { Component, Inject, OnDestroy, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import {
  getAuth,
  EmailAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  setPersistence,
  browserLocalPersistence,
  signInWithRedirect,
  Auth,
  User
} from 'firebase/auth';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  public loginDialogRef?: MatDialogRef<any>;
  public firebaseUI: any;
  public currentUser: User | null = null;
  public activeRoute: string | null = null;
  public destroy$ = new Subject<void>();

  @ViewChild('loginDialogTemplate') loginDialogTemplate!: TemplateRef<any>;

  public constructor(
    private readonly dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: string,
    private activatedRoute: ActivatedRoute,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.firebaseUI = require('@smart-leiloes/firebaseui-web');
      const firebaseAuth = getAuth();
      setPersistence(firebaseAuth, browserLocalPersistence);
      firebaseAuth.onAuthStateChanged(
        user => {
          this.currentUser = user;
        },
        err => {}
      );
    }

    this.activatedRoute.url.pipe(
      tap(
        urlSegments => {
          this.activeRoute = urlSegments[0].path;
        },
      ),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public showMenu = false;

  public toggleNavbar() {
    this.showMenu = !this.showMenu;
  }

  public tempRedirectWordpress(): void {
    window.open('https://teamsmartleiloes.com.br/smartcrecis');
  }

  public downloadPlanilhaCaixa(): void {
    window.open(
      'https://storage.googleapis.com/excel-extracoes-imoveis-smart-leiloes/excel/Smart%20Leil%C3%B5es%20-%20Im%C3%B3veis%20Caixa.xlsx'
    );
  }

  public login() {
    // Initialize the FirebaseUI Widget using Firebase.
    const firebaseAuth = getAuth();
    const ui = new this.firebaseUI.auth.AuthUI(firebaseAuth);
    this.loginDialogRef = this.dialog.open(this.loginDialogTemplate, {
      minWidth: '40vw',
      maxWidth: '90vw'
    });
    ui.start('#loginDialog', {
      signInFlow: 'popup',
      signInOptions: [EmailAuthProvider.PROVIDER_ID, GoogleAuthProvider.PROVIDER_ID, FacebookAuthProvider.PROVIDER_ID],
      callbacks: {
        signInSuccessWithAuthResult: (authResult: any, redirectUrl: any) => {
          console.log('authResult', authResult);
          console.log('redirectUrl', redirectUrl);
        }
      }
    });
  }
}
