<div class="ml-[3%] mt-24 sm:mt-5">
  <smart-breadcrumb
    [config]="breadcrumbConfig"
  ></smart-breadcrumb>
</div>
<main id="contact" class="flex flex-col mt-28 md:mt-0">
  <div class="p-6 lg:py-28 lg:px-[340px]">
    <h1 class="text-blue-900 text-3xl mb-3 font-bold">Fale conosco</h1>

    <span class="text-base text-blue-900">
      Ficou alguma dúvida?<br />
      Escreva uma mensagem para nossos especialistas:
    </span>

    <div class="flex flex-col mt-8 text-base" [formGroup]="formContato">
      <mat-form-field class="mb-3">
        <mat-label>Nome</mat-label>
        <input matInput type="text" formControlName="nome" />
        <mat-error *ngIf="formContato.controls.nome.invalid"> Nome é um campo obrigatório </mat-error>
      </mat-form-field>

      <mat-form-field class="mb-3">
        <mat-label>Qual seu e-mail?</mat-label>
        <input matInput type="text" formControlName="email" />
        <mat-error *ngIf="formContato.controls.email.invalid && formContato.controls.email.errors?.['required']"> Email é um campo obrigatório </mat-error>
        <mat-error *ngIf="formContato.controls.email.invalid && formContato.controls.email?.errors?.['email']"> Email inválido </mat-error>
      </mat-form-field>

      <mat-form-field class="mb-3">
        <mat-label>Qual seu celular?</mat-label>
        <input matInput type="text" formControlName="celular" />
        <mat-error *ngIf="formContato.controls.celular.invalid"> Celular é um campo obrigatório </mat-error>
      </mat-form-field>

      <mat-form-field class="mb-3">
        <mat-label>Sua mensagem</mat-label>
        <textarea matInput formControlName="mensagem" rows="6"></textarea>
        <mat-error *ngIf="formContato.controls.mensagem.invalid"> Mensagem é um campo obrigatório </mat-error>
      </mat-form-field>

      <button class="bg-orange-brand rounded block py-4 hover:bg-orange-700 text-white" (click)="enviarContato()">
        Enviar
      </button>
    </div>
  </div>
</main>
